import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  TextBox,
  BlogSlider,
  BannerLinks,
  Breadcrumbs,
  CallUs
} from '../../components'

import BannerImage from '../../assets/projektuebersicht.jpg'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

const CrossTesting = () => {
  const links = generateBreadcrumbsLinks('/blog/cross-testing', 'Cross-Testing')
  return (
    <Layout>
      <Helmet
        title="Cross-Testing: wie funktioniert das?"
        meta={[
          {
            name: 'description',
            content:
              'Bevor unsere Websiten ins große WWW entlassen werden müssen diese noch auf Herz und Nieren getestet werden. Wir von holzweg setzen uns in großer Runde zusammen, jeder bekommt ein Gerät und sieht sich die einzelnen Seiten der Website durch, testet Formulare, Newsletter Anmeldungen, Downloads und vieles mehr.'
          },
          {
            name: 'keywords',
            content:
              'Cross-Testing holzweg, Blog holzweg, Innsbruck, holzweg, Blog, Cross-Testing, Testing, Browsertest, verschiedene Browser, Online-Gang'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/projektuebersicht-3b0d0cca7e33df5e55e7b68d779cc633.jpg'
          },
          {
            property: 'og:description',
            content:
              'Bevor unsere Websiten ins große WWW entlassen werden müssen diese noch auf Herz und Nieren getestet werden. Wir von holzweg setzen uns in großer Runde zusammen, jeder bekommt ein Gerät und sieht sich die einzelnen Seiten der Website durch, testet Formulare, Newsletter Anmeldungen, Downloads und vieles mehr.'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/projektuebersicht-3b0d0cca7e33df5e55e7b68d779cc633.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Gemeinsam
            <br />
            macht es mehr Spaß!
          </span>
        }
      />
      <Breadcrumbs
          backLinkAlias=' '
          links={links}
        />
      <TextBox
        title="Cross-Testing: wie funktioniert das?"
        text={
          <span>
            <p>Bevor unsere Websiten <i>"ins große WWW"</i> entlassen werden müssen diese noch auf Herz und Nieren getestet werden.
            </p>
            <p>Es gibt zwar Tools, mittels denen man automatisiert über viele Browser und Endgeräte die Website durchtesten kann, aber: <b>Vertrauen ist gut, Kontrolle ist besser!</b>
            </p>
            <p>Deshalb setzen wir uns in großer Runde zusammen, jeder bekommt ein Gerät und sieht sich die einzelnen Seiten der Website durch, testet Formulare, Newsletter Anmeldungen, Downloads und vieles mehr.
            </p>
            <p>Heutzutage werden Webseiten zu <b>60 % mit Smartphones</b> aufgerufen, also ist es um so wichtiger, dass unsere Webseiten für alle möglichen Bildschirmgrößen optimiert sind. Ebenso hat sich die Anzahl an Web Browsern in den letzten Jahren erhöht, somit muss noch intensiver getestet werden um Anzeigefehler zu verhindern.</p>
            <h3>Wollen Sie einmal mit uns ein Cross-Testing machen?</h3>
            <p>Dann melden Sie sich bei unseren Ansprechpartnern für Ihr Projekt:</p>
          </span>
        }
      />
      <CallUs contacts={contacts} title="Rufen Sie uns an:" />
      <BlogSlider cards={cards} singleRow={true} title="Weitere Blogeinträge"/>
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default CrossTesting
